import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CommonLoader({show=false}) {

  return show? (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  ):null;
}