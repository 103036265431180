import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, TextField } from "@mui/material";
import { MyTheme } from "src/layouts/customTheme";
import { toast } from "react-toastify";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { showDate } from "src/components/commonfunctions";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "max-content",
  maxHeight: "80%",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
  p: 4,
};

export default function DescriptionModal({ open, setOpen, payload }) {
  const copyIt = (field, val) => {
    navigator.clipboard.writeText(val);
    toast.success(field + " copied");
  };

  const bulletPointText = payload?.services
    ?.map((item) => `• ${item.label}: ${item.value}`)
    .join("\n");

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={setOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            fontWeight="bold"
            textAlign="center"
            color={MyTheme.bgColor1}
          >
            Quote Details
          </Typography>
          <Grid
            container
            rowSpacing={2}
            mt={3}
            fontWeight="bold"
            fontSize={18}
            alignItems="center"
            style={{ paddingBottom: "50px" }}
          >
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" fontSize={16}>
                  Name
                </Typography>
                <CopyAllIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    zIndex: 10,

                    top: "2.1rem",
                    color: MyTheme.bgColor1,
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                  onClick={() => copyIt("Name", payload?.name)}
                />
              </div>
              <TextField
                value={payload?.firstName + " " + payload?.lastName}
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative"
                }}
              >
                <Typography fontWeight="bold" fontSize={16}>
                  {payload?.contact?payload?.platform : "Contact"}
                </Typography>
                <CopyAllIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    zIndex: 10,
                    top: "2.1rem",
                    color: MyTheme.bgColor1,
                    cursor: "pointer"
                  }}
                  fontSize="medium"
                  onClick={() =>
                    copyIt(payload?.platform, payload?.contact)
                  }
                />
              </div>
              <TextField value={payload?.contact ||payload?.phone } fullWidth />
            </Grid> */}
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" fontSize={16}>
                  Email
                </Typography>
                <CopyAllIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    zIndex: 10,
                    top: "2.1rem",
                    color: MyTheme.bgColor1,
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                  onClick={() => copyIt("Email", payload?.email)}
                />
              </div>
              <TextField value={payload?.email} fullWidth />
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" fontSize={16}>
                  Phone
                </Typography>
                <CopyAllIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    zIndex: 10,
                    top: "2.1rem",
                    color: MyTheme.bgColor1,
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                  onClick={() => copyIt("Phone", payload?.phone)}
                />
              </div>
              <TextField value={payload?.phone} fullWidth />
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" fontSize={16}>
                  Company Name
                </Typography>
                <CopyAllIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    zIndex: 10,
                    top: "2.1rem",
                    color: MyTheme.bgColor1,
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                  onClick={() => copyIt("CompanyName", payload?.companyName)}
                />
              </div>
              <TextField value={payload?.companyName} fullWidth />
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" fontSize={16}>
                  Corporate Email
                </Typography>
                <CopyAllIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    zIndex: 10,
                    top: "2.1rem",
                    color: MyTheme.bgColor1,
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                  onClick={() =>
                    copyIt("Corporate Email", payload?.corporateEmail)
                  }
                />
              </div>
              <TextField value={payload?.corporateEmail} fullWidth />
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" fontSize={16}>
                  Project Description
                </Typography>
                <CopyAllIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    zIndex: 10,
                    top: "2.1rem",
                    color: MyTheme.bgColor1,
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                  onClick={() =>
                    copyIt("Project Description", payload?.projectDescription)
                  }
                />
              </div>
              <TextField value={payload?.projectDescription} fullWidth />
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" fontSize={16}>
                  Additional Infromation
                </Typography>
                <CopyAllIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    zIndex: 10,
                    top: "2.1rem",
                    color: MyTheme.bgColor1,
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                  onClick={() =>
                    copyIt(
                      "Additional Information",
                      payload?.additionalInfromation
                    )
                  }
                />
              </div>
              <TextField value={payload?.additionalInformation} fullWidth />
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" fontSize={16}>
                  Services
                </Typography>
                <CopyAllIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    zIndex: 10,
                    top: "2.1rem",
                    color: MyTheme.bgColor1,
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                  onClick={() =>
                    copyIt("Additional Information", bulletPointText)
                  }
                />
              </div>
              {/* <TextField value={JSON.stringify(payload?.services) }fullWidth /> */}
              <TextField
                value={bulletPointText}
                multiline
                fullWidth
                variant="outlined"
                inputProps={{
                  style: { whiteSpace: "pre-wrap" }, // Preserve newlines
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" fontSize={16}>
                  Expected Start Date
                </Typography>
                <CopyAllIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    zIndex: 10,
                    top: "2.1rem",
                    color: MyTheme.bgColor1,
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                  onClick={() =>
                    copyIt("Expected Date", payload?.expectedStartDate)
                  }
                />
              </div>
              <TextField
                value={showDate(payload?.expectedStartDate)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" fontSize={16}>
                  Expected Completion Date
                </Typography>
                <CopyAllIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    zIndex: 10,
                    top: "2.1rem",
                    color: MyTheme.bgColor1,
                    cursor: "pointer",
                  }}
                  fontSize="medium"
                  onClick={() =>
                    copyIt("Expected Date", payload?.expectedCompletionDate)
                  }
                />
              </div>
              <TextField
                value={showDate(payload?.expectedCompletionDate)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" fontSize={16}>
                  Attached Files
                </Typography>
                {/* <CopyAllIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    zIndex: 10,
                    top: "2.1rem",
                    color: MyTheme.bgColor1,
                    cursor: "pointer"
                  }}
                  fontSize="medium"
                  onClick={() =>
                    copyIt("Expected Date", '')
                  }
                /> */}
              </div>
              {/* <TextField value={ bulletPointFiles }fullWidth /> */}
              <Typography
                component="div"
                style={{ border: "1px solid #cecece", borderRadius: "5px" }}
              >
                {payload?.files?.length > 0 && payload?.files[0] != null ? (
                  <ul>
                    {payload?.files?.map(
                      (file, index) =>
                        file !== null && (
                          <li key={index}>
                            <Link to={file} target="_blank">
                              {file}
                            </Link>
                          </li>
                        )
                    )}
                  </ul>
                ) : (
                  <div style={{ padding: "16.5px 14px 16.5px 14px" }}>
                    {" "}
                    <div>Not Provided</div>
                  </div>
                )}
              </Typography>
            </Grid>

            {/* <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={12} md={8}>
                  Content Details
                </Grid>
              </Grid>

              <Grid
                container
                fontWeight="400"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
                rowSpacing={2}
              >
                <Grid item xs={3}>
                  {payload?.platform}
                </Grid>
                <Grid item xs={9} textAlign="right">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end"
                    }}
                  >
                    {payload?.contact ? payload?.contact : "NA"}{" "}
                    <CopyAllIcon
                      style={{
                        color: MyTheme.bgColor1,
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        copyIt("Telegram Id", payload?.telegram)
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
          {payload?.message && (
            <Grid item xs={12} mt={4}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  // mt={3}
                  alignItems="center"
                  fontWeight="bold"
                  fontSize={18}
                  // bgcolor="	#E5E4E2"
                  borderRadius={2}
                  color={MyTheme.bgColor1}
                  // p={1}
                >
                  Message{" "}
                </Typography>
                {/* <CopyAllIcon
                style={{
                  color: MyTheme.bgColor1,
                  cursor: "pointer"
                }}
                onClick={() => copyIt("Message", payload?.message)}
              /> */}
              </div>
            </Grid>
          )}

          {payload?.message && (
            <>
              <Typography
                id="modal-modal-description"
                p={1}
                // sx={{ mt: 2 }}
                bgcolor="#F6F6F6"
                borderRadius={2}
                mt={2}
              >
                {payload?.message}
              </Typography>
            </>
          )}
          <Grid container justifyContent="end" mt={3}>
            <Grid item xs={4} textAlign="right">
              {" "}
              <Button variant="outlined" onClick={setOpen} color="error">
                Close
              </Button>{" "}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
