import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Delete, Search as SearchIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import { API_CALL } from "src/services/APICalls";
import DeleteModal from "../../components/DeleteModel/DeleteModel";
import { showDate } from "src/components/commonfunctions";
import DescriptionModal from "./DescriptionModal";
import { IoIosEye } from "react-icons/io";
import {
  debounceDelay,
  pageSize,
  rowsPerPageOptions,
} from "src/constants/constant";
import { useDebounce } from "use-debounce";

export const Leads = () => {
  const [leads, setLeads] = useState({
    totalCount: 0,
    leads: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({ platform: "All" });
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: pageSize,
  });
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    isFormModalOpen: false,
  });
  const [debouncedValue] = useDebounce(filter.search, debounceDelay);

  const theme = useTheme();

  const dropdownOption = [
    "All",
    "Whatsapp",
    "Discord",
    "Email",
    "Telegram",
    "Linkedin",
    "Skype",
  ];

  const fetchLeads = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        const query = new URLSearchParams({
          page: (pagination.page + 1).toString(),
          limit: pagination.pageSize.toString(),
          ...(data?.debouncedValue?.length > 0 && {
            search: data?.debouncedValue,
          }),
          ...(filter.platform && { platform: filter.platform }),
        });

        const response = await API_CALL.lead.get(query);
        setLeads(response?.data?.data || { totalCount: 0, leads: [] });
      } catch (error) {
        console.error("Error fetching leads:", error);
        toast.dismiss();
        toast.error("Failed to fetch leads");
      } finally {
        setIsLoading(false);
      }
    },
    [pagination.page, pagination.pageSize, filter.platform]
  );

  useEffect(() => {
    fetchLeads({ debouncedValue });
  }, [debouncedValue, fetchLeads]);

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize, page: 0 }));
  };

  const handleDelete = async (id) => {
    try {
      await API_CALL.lead.delete(id);
      fetchLeads({ debouncedValue });
      setModalState((prev) => ({ ...prev, isDeleteModalOpen: false }));
      toast.dismiss();
      toast.success("Lead deleted successfully");
    } catch (error) {
      console.error("Error deleting lead:", error);
      toast.dismiss();
      toast.error("Failed to delete lead");
    }
  };

  const columns = [
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => params?.value || "-",
    },
    {
      field: "platform",
      headerName: "Platform",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => params.value || "-",
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => params.value || "-",
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => showDate(params?.value) || "-",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          <IconButton
            onClick={() => {
              setModalState((prev) => ({
                ...prev,
                isFormModalOpen: true,
                data: params.row,
              }));
            }}
            size="small"
            sx={{
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
            }}
          >
            <IoIosEye
              style={{ fontSize: "1.3rem", color: theme.palette.primary.dark }}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              setModalState((prev) => ({
                ...prev,
                isDeleteModalOpen: true,
                data: {
                  _id: params?.row?._id,
                  title: params?.row?.fullName,
                },
              }));
            }}
            size="small"
            sx={{
              color: theme.palette.error.main,
              "&:hover": {
                backgroundColor: theme.palette.error.light,
                color: theme.palette.error.dark,
              },
            }}
          >
            <Delete sx={{ fontSize: "1.3rem" }} />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="h5"
              component="h1"
              sx={{ mb: { xs: 2, sm: 0 } }}
            >
              Leads
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter.platform}
              sx={{ minWidth: "120px" }}
              onChange={(e) => {
                setFilter({ ...filter, platform: e.target.value });
              }}
            >
              {dropdownOption.map((item, key) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box sx={{ mb: 3 }}>
            <TextField
              placeholder="Search"
              variant="outlined"
              value={filter.search}
              onChange={(e) => {
                setFilter((prev) => ({
                  ...prev,
                  search: e.target.value,
                }));
                setPagination((prev) => ({ ...prev, page: 0 }));
              }}
              color="primary"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: theme.palette.primary.dark,
                  },
                },
              }}
              InputProps={{ startAdornment: <SearchIcon /> }}
              fullWidth
            />
          </Box>
          <DataGrid
            rows={leads.leads}
            columns={columns}
            pagination
            page={pagination.page}
            pageSize={pagination.pageSize}
            rowCount={leads.totalCount}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            loading={isLoading}
            paginationMode="server"
            getRowId={(row) => row._id}
            disableSelectionOnClick
            checkboxSelection
            autoHeight
            sx={{
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
              "& .MuiDataGrid-columnHeaders": {
                borderBottom: "2px solid rgba(224, 224, 224, 1)",
              },
              "& .MuiDataGrid-row": {
                "&:nth-of-type(odd)": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              },
            }}
          />
        </CardContent>
      </Card>
      <DeleteModal
        isModalOpen={modalState.isDeleteModalOpen}
        setIsModalOpen={(isOpen) =>
          setModalState((prev) => ({ ...prev, isDeleteModalOpen: isOpen }))
        }
        handleDelete={handleDelete}
        payload={modalState.data}
      />
      <DescriptionModal
        open={modalState.isFormModalOpen}
        setOpen={() =>
          setModalState((prev) => ({ ...prev, isFormModalOpen: false }))
        }
        payload={modalState.data}
      />
    </Box>
  );
};
