import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
});

const ModalForm = ({ open, onClose, action, payload, handleSubmitAction }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const theme = useTheme();

  useEffect(() => {
    if (action === "update" && payload) {
      reset(payload);
    } else {
      reset({ title: "", isActive: false });
    }
  }, [action, payload, reset]);

  const onSubmit = (data) => {
    if (action === "update") {
      handleSubmitAction({
        action: "update",
        payload: { _id: payload._id, ...data },
      });
      reset();
    } else if (action === "add") {
      handleSubmitAction({ action: "add", payload: data });
      reset();
    } else {
      toast.error("Invalid Action");
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {action === "update" ? "Update" : "Add"} Blog Category
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Title"
                    fullWidth
                    error={!!errors.title}
                    helperText={errors.title?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="isActive"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: theme.palette.primary.dark,
                            },
                        }}
                        {...field}
                        checked={field.value}
                      />
                    }
                    label="Activation Status"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              color: "#FFFFFF",
              "&:hover": { backgroundColor: theme.palette.primary.dark },
            }}
          >
            {action === "update" ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalForm;
