import { BASE_CALL } from "./baseCall";

export const API_CALL = {
  sunscription: {
    get: async (payload) => await BASE_CALL.get("/subscriber/get", payload),
    delete: async (payload) =>
      await BASE_CALL.delete("/subscriber/delete/" + payload.id),
    update: async (payload) =>
      await BASE_CALL.put("/subscriber/update", payload),
  },
  users: {
    create: async (payload) => BASE_CALL.post("/user/create", payload),
    getByWallet: async (walletAddress) =>
      await BASE_CALL.get("/user/get-by-wallet", { walletAddress }),
    get: async (payload) => await BASE_CALL.get("/user/get", payload),
    login: async (payload) => await BASE_CALL.post("/user/login", payload),
    verify: async (payload) => await BASE_CALL.post("/user/validate-token"),
    ResetPassword: async (payload) =>
      await BASE_CALL.post("/user/resetPassword", payload),
  },
  lead: {
    get: async (payload) => await BASE_CALL.get("/subscriber/get", payload),
    delete: async (payload) =>
      await BASE_CALL.delete("/subscriber/delete", payload),
  },
  ContactUs: {
    get: async (payload) => await BASE_CALL.get("/contactus/get", payload),
    delete: async (payload) =>
      await BASE_CALL.delete("/contactus/delete", payload),
  },
  Quote: {
    get: async (payload) => await BASE_CALL.get("/quotes/get", payload),
    delete: async (payload) =>
      await BASE_CALL.delete("/quotes/delete", payload),
  },
  BlogCategories: {
    create: async (payload) =>
      await BASE_CALL.post("/blogCategories/create", payload),
    get: async (payload) => await BASE_CALL.get("/blogCategories/get", payload),
    update: async (payload) =>
      await BASE_CALL.put("/blogCategories/update", payload),
    delete: async (id) => await BASE_CALL.delete("/blogCategories/delete", id),
  },
  Blogs: {
    create: async (payload) => await BASE_CALL.post("/blogs/create", payload),
    get: async (payload) => await BASE_CALL.get("/blogs/get", payload),
    getById: async (payload) =>
      await BASE_CALL.get(`/blogs/getById/${payload}`),
    update: async (payload) => await BASE_CALL.put("/blogs/update", payload),
    delete: async (id) => await BASE_CALL.delete("/blogs/delete", id),
  },
};
