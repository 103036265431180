import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";
import { BlogCategories } from "src/views/blogCategories/BlogCategories";
import { Blogs } from "src/views/blogs/Blogs";
import { BlogForm } from "src/views/blogs/AddAndUpdateModel";
import { ContactUs } from "src/views/contactus/ContactUs";
import { Quotes } from "src/views/quotes/Quotes";
import { Leads } from "src/views/leads/Leads";
import { ResetPassword } from "src/views/resetPassword/ResetPassword";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout"))
);

/* ****Pages***** */

const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Register = Loadable(
  lazy(() => import("../views/authentication/Register"))
);
const Login = Loadable(lazy(() => import("../views/authentication/Login")));

const Router = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/manage-leads", element: <Leads /> },
      { path: "/manage-contactus", exact: true, element: <ContactUs /> },
      { path: "/manage-quotes", exact: true, element: <Quotes /> },
      {
        path: "/manage-blog-categories",
        exact: true,
        element: <BlogCategories />,
      },
      {
        path: "/manage-blogs",
        exact: true,
        element: <Blogs />,
      },
      {
        path: "/manage-resetPassword",
        exact: true,
        element: <ResetPassword />,
      },
      {
        path: "/manage-blogs/add",
        exact: true,
        element: <BlogForm />,
      },
      {
        path: "/manage-blogs/edit/:id",
        // exact: true,
        element: <BlogForm />,
      },
      { path: "dashboard", element: <Navigate to="/manage-leads" /> },
      { path: "*", element: <Navigate to="/manage-leads" /> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "/auth/register", element: <Register /> },
      { path: "/auth/login", element: <Login /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
