import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  MenuItem,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { API_CALL } from "src/services/APICalls";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CommonLoader from "src/theme/loader";
import { useTheme } from "@emotion/react";
import { Close } from "@mui/icons-material";
import "highlight.js/styles/github.css";
import RichTextEditor from "src/components/TextEditor/TextEditor";

const schema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .min(3)
    .required("Title is required")
    .label("Title"),
  blogCategory: yup.string().required("Blog category is required"),
  shortDescription: yup
    .string()
    .trim()
    .min(10)
    .required("Short description is required")
    .label("Short description")
    .test("maxWords", "Short description cannot exceed 50 words", (value) => {
      if (!value) return true; // Allow empty values to be handled by .required
      return value.split(/\s+/).length <= 70;
    }),
  description: yup
    .string()
    .trim()
    .required("Description is required")
    .typeError("Description is required"),

  coverImageUrl: yup
    .string()
    .required("Cover image is required")
    .typeError("Cover image is required"),
  bannerImageUrl: yup
    .string()
    .required("Banner image is required")
    .typeError("Cover image is required"),
});

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 1200,
  margin: "auto",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(4),
  boxShadow: theme.shadows[3],
}));

export const BlogForm = () => {
  const [blogCategories, setBlogCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [bannerImagePreview, setBannerImagePreview] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    fetchBlogCategories();
    if (id) fetchBlogDetails(id);
    // eslint-disable-next-line
  }, [id]);

  const fetchBlogCategories = async () => {
    try {
      const query = new URLSearchParams({
        isActive: true,
      });
      const response = await API_CALL.BlogCategories.get(query);
      setBlogCategories(response?.data?.data.blogCategories);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
      toast.dismiss();
      toast.error("Failed to fetch blog categories");
    }
  };

  const fetchBlogDetails = async (blogId) => {
    try {
      setIsLoading(true);
      const response = await API_CALL.Blogs.getById(blogId);
      const blogData = response?.data?.data;
      reset({
        ...blogData,
        blogCategory: blogData?.blogCategory?._id,
        description: blogData.description || "",
      });
      setCoverImagePreview(blogData.coverImageUrl);
      setBannerImagePreview(blogData.bannerImageUrl);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching blog details:", error);
      toast.dismiss();
      toast.error("Failed to fetch blog details");
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      formData.append("title", data?.title);
      formData.append("blogCategory", data?.blogCategory);
      formData.append("shortDescription", data?.shortDescription);
      formData.append("description", data?.description);

      if (data.coverImageUrl && data.coverImageUrl.startsWith("data:")) {
        const coverImageBlob = await fetch(data.coverImageUrl).then((item) =>
          item.blob()
        );
        formData.append("coverImage", coverImageBlob, "cover_image.jpg");
      } else if (data.coverImageUrl) {
        formData.append("coverImageUrl", data.coverImageUrl);
      }

      if (data.bannerImageUrl && data.bannerImageUrl.startsWith("data:")) {
        const bannerImageBlob = await fetch(data.bannerImageUrl).then((item) =>
          item.blob()
        );
        formData.append("bannerImage", bannerImageBlob, "banner_image.jpg");
      } else if (data.bannerImageUrl) {
        formData.append("bannerImageUrl", data.bannerImageUrl);
      }

      if (id) {
        await API_CALL.Blogs.update({ id, data: formData });
        setIsLoading(false);
        toast.dismiss();
        toast.success("Blog updated successfully");
      } else {
        await API_CALL.Blogs.create(formData);
        setIsLoading(false);
        toast.dismiss();
        toast.success("Blog added successfully");
      }
      navigate("/manage-blogs");
    } catch (error) {
      setIsLoading(false);
      console.error("Error submitting blog:", error);
      toast.dismiss();
      toast.error("Something went wrong");
    }
  };

  const handleImageChange = (event, imageType) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (imageType === "cover") {
          setCoverImagePreview(reader.result);
          setValue("coverImageUrl", reader.result);
          trigger("coverImageUrl");
        } else {
          setBannerImagePreview(reader.result);
          setValue("bannerImageUrl", reader.result);
          trigger("bannerImageUrl");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = (imageType) => {
    if (imageType === "cover") {
      setCoverImagePreview(null);
      setValue("coverImageUrl", null);
      trigger("coverImageUrl");
    } else {
      setBannerImagePreview(null);
      setValue("bannerImageUrl", null);
      trigger("bannerImageUrl");
    }
  };

  return (
    <StyledCard>
      <CardContent sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CommonLoader show={isLoading} />
        </Box>
        <Typography sx={{ paddingBottom: "20px" }} variant="h4" gutterBottom>
          {id ? "Update" : "Add"} Blog Post
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Title"
                    fullWidth
                    variant="outlined"
                    error={!!errors.title}
                    helperText={errors.title?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="blogCategory"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label={id ? "Blog Category" : "Select Blog Category"}
                    fullWidth
                    variant="outlined"
                    error={!!errors.blogCategory}
                    helperText={errors.blogCategory?.message}
                  >
                    {blogCategories?.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.title}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="shortDescription"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Short Description"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    error={!!errors.shortDescription}
                    helperText={errors.shortDescription?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom>
                Cover Image
              </Typography>
              <input
                accept="image/*"
                type="file"
                onChange={(e) => handleImageChange(e, "cover")}
                style={{ display: "none" }}
                id="cover-image-upload"
              />
              <label htmlFor="cover-image-upload">
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  color="primary"
                  sx={{
                    marginRight: 1,
                    "&:hover": {
                      color: theme.palette.primary.dark,
                      borderColor: theme.palette.primary.dark,
                    },
                  }}
                >
                  Upload Cover Image
                </Button>
              </label>

              {coverImagePreview && (
                <Box
                  sx={{
                    marginTop: "15px",
                    display: "flex ",
                    justifyContent: "start",
                    gap: "5px",
                  }}
                >
                  <img
                    src={coverImagePreview}
                    alt="Cover"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />

                  <Close
                    sx={{
                      position: "relative",
                      top: -5,
                      // right: -25,
                      color: "red",
                      cursor: "pointer",
                      backgroundColor: "#FDF4FF",
                      borderRadius: "50%",
                      padding: "2px",
                      "&:hover": {
                        transition: "all 0.3s",
                      },
                    }}
                    onClick={() => handleImageDelete("cover")}
                  />
                </Box>
              )}
              {errors.coverImageUrl && (
                <Typography color="error">
                  {errors.coverImageUrl.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom>
                Banner Image
              </Typography>
              <input
                accept="image/*"
                type="file"
                onChange={(e) => handleImageChange(e, "banner")}
                style={{ display: "none" }}
                id="banner-image-upload"
              />
              <label htmlFor="banner-image-upload">
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  color="primary"
                  sx={{
                    marginRight: 1,
                    "&:hover": {
                      color: theme.palette.primary.dark,
                      borderColor: theme.palette.primary.dark,
                    },
                  }}
                >
                  Upload Banner Image
                </Button>
              </label>
              {bannerImagePreview && (
                <Box
                  sx={{
                    marginTop: "15px",
                    display: "flex ",
                    justifyContent: "start",
                    gap: "5px",
                  }}
                >
                  <img
                    src={bannerImagePreview}
                    alt="Cover"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />

                  <Close
                    sx={{
                      position: "relative",
                      top: -5,
                      color: "red",
                      cursor: "pointer",
                      backgroundColor: "#FDF4FF",
                      borderRadius: "50%",
                      padding: "2px",
                      "&:hover": {
                        transition: "all 0.3s",
                      },
                    }}
                    onClick={() => handleImageDelete("banner")}
                  />
                </Box>
              )}
              {errors.bannerImageUrl && (
                <Typography color="error">
                  {errors.bannerImageUrl.message}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <Typography variant="subtitle1" gutterBottom>
                      Description
                    </Typography>
                    <Box>
                      <RichTextEditor
                        value={field.value}
                        onChange={(content) => {
                          field.onChange(content);
                          trigger("description");
                        }}
                      />
                      {errors.description && (
                        <Typography color="error" variant="caption">
                          {errors.description.message}
                        </Typography>
                      )}
                    </Box>
                  </>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end", padding: 2 }}>
        <Button
          color="primary"
          onClick={() => navigate("/manage-blogs")}
          variant="outlined"
          sx={{
            marginRight: 1,
            "&:hover": {
              color: theme.palette.primary.dark,
              borderColor: theme.palette.primary.dark,
            },
          }}
          disabled={isLoading}
        >
          Back
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
          sx={{
            color: "#FFFFFF",
            "&:hover": { backgroundColor: theme.palette.primary.dark },
          }}
          disabled={isLoading}
        >
          {id ? "Update" : "Add"} Blog
        </Button>
      </CardActions>
    </StyledCard>
  );
};
