import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  IconButton,
  Switch,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Delete, Edit, Search as SearchIcon } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../utilities/helpers";
import { API_CALL } from "src/services/APICalls";
import DeleteModal from "../../components/DeleteModel/DeleteModel";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import {
  debounceDelay,
  pageSize,
  rowsPerPageOptions,
} from "src/constants/constant";

export const Blogs = () => {
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState({
    totalCount: 0,
    blogs: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: pageSize,
  });
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    isFormModalOpen: false,
  });
  const [debouncedValue] = useDebounce(searchQuery, debounceDelay);

  const theme = useTheme();

  const fetchBlogs = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        const query = new URLSearchParams({
          page: (pagination.page + 1).toString(),
          limit: pagination.pageSize.toString(),
          ...(data?.debouncedValue?.length > 0 && {
            search: data.debouncedValue,
          }),
        });

        const response = await API_CALL.Blogs.get(query);

        setBlogs(response?.data?.data || { totalCount: 0, blogs: [] });
      } catch (error) {
        console.error("Error fetching blog categories:", error);
        toast.dismiss();
        toast.error(
          error?.response?.data?.message || "Failed to fetch blog categories"
        );
      } finally {
        setIsLoading(false);
      }
    },
    [pagination?.page, pagination?.pageSize]
  );

  useEffect(() => {
    fetchBlogs({ debouncedValue });
  }, [debouncedValue, fetchBlogs]);

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize, page: 0 }));
  };

  const handleDelete = async (id) => {
    try {
      await API_CALL.Blogs.delete(id);
      fetchBlogs({ debouncedValue });
      setModalState((prev) => ({ ...prev, isDeleteModalOpen: false }));
      toast.dismiss();
      toast.success("Blog deleted successfully");
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.dismiss();
      toast.error(
        error?.response?.data?.message || "Failed to delete category"
      );
    }
  };

  const handleUpdate = async ({ id, data }) => {
    try {
      await API_CALL.Blogs.update({ id, data });
      toast.dismiss();
      toast.success(
        `Blog ${data?.isActive ? "Activated" : "Deactivated"} Successfully`
      );
      fetchBlogs();
    } catch (error) {
      console.error("Error updating category:", error);
      toast.dismiss();
      toast.error(
        error?.response?.data?.message || "Failed to update category"
      );
    }
  };

  const columns = [
    {
      field: "blogCategory",
      headerName: "Blog Category",
      flex: 1,
      minWidth: 100,
      renderCell: (params) =>
        capitalizeFirstLetter(params.row.blogCategory.title) || "-",
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 400,
      renderCell: (params) =>
        params?.value?.length > 60
          ? `${params?.value?.substring(0, 60)}...`
          : params?.value || "-",
    },
    {
      field: "isActive",
      headerName: "Activation",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Switch
          checked={params.row.isActive}
          onChange={() =>
            handleUpdate({
              id: params.row._id,
              data: { isActive: !params.row.isActive },
            })
          }
          color="primary"
          sx={{
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          <IconButton
            onClick={() => {
              navigate(`/manage-blogs/edit/${params.row._id}`);
            }}
            size="small"
            sx={{
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
            }}
          >
            <Edit color="primary" style={{ fontSize: "1.3rem" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setModalState((prev) => ({
                ...prev,
                isDeleteModalOpen: true,
                data: params.row,
              }));
            }}
            size="small"
            sx={{
              color: theme.palette.error.main,
              "&:hover": {
                backgroundColor: theme.palette.error.light,
                color: theme.palette.error.dark,
              },
            }}
          >
            <Delete sx={{ fontSize: "1.3rem" }} />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="h5"
              component="h1"
              sx={{ mb: { xs: 2, sm: 0 } }}
            >
              Blogs
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              color="primary"
              sx={{
                borderRadius: "10px",
                textTransform: "none",
                color: "#FFFFFF",
                padding: "8px 16px",
                "&:hover": { backgroundColor: theme.palette.primary.dark },
              }}
              onClick={() => {
                navigate("/manage-blogs/add");
              }}
            >
              Add Blog
            </Button>
          </Box>
          <Box sx={{ mb: 3 }}>
            <TextField
              placeholder="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setPagination((prev) => ({ ...prev, page: 0 }));
              }}
              InputProps={{ startAdornment: <SearchIcon /> }}
              fullWidth
              color="primary"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: theme.palette.primary.dark,
                  },
                },
              }}
            />
          </Box>
          <DataGrid
            rows={blogs.blogs}
            columns={columns}
            pagination
            page={pagination.page}
            pageSize={pagination.pageSize}
            rowCount={blogs.totalCount}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            loading={isLoading}
            paginationMode="server"
            getRowId={(row) => row._id}
            disableSelectionOnClick
            checkboxSelection
            autoHeight
            sx={{
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
              "& .MuiDataGrid-columnHeaders": {
                borderBottom: "2px solid rgba(224, 224, 224, 1)",
              },
              "& .MuiDataGrid-row": {
                "&:nth-of-type(odd)": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              },
            }}
          />
          <DeleteModal
            isModalOpen={modalState.isDeleteModalOpen}
            setIsModalOpen={(isOpen) =>
              setModalState((prev) => ({ ...prev, isDeleteModalOpen: isOpen }))
            }
            handleDelete={handleDelete}
            payload={modalState.data}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
