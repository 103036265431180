import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Delete, Search as SearchIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../utilities/helpers";
import { API_CALL } from "src/services/APICalls";
import DeleteModal from "../../components/DeleteModel/DeleteModel";
import { showDate } from "src/components/commonfunctions";
import DescriptionModal from "./DescriptionModal";
import { IoIosEye } from "react-icons/io";
import { useDebounce } from "use-debounce";
import {
  debounceDelay,
  pageSize,
  rowsPerPageOptions,
} from "src/constants/constant";

export const ContactUs = () => {
  const [contactUs, setContactUs] = useState({
    totalCount: 0,
    contactUs: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: pageSize,
  });
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    isFormModalOpen: false,
  });
  const [debouncedValue] = useDebounce(searchQuery, debounceDelay);

  const theme = useTheme();

  const fetchContactUs = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        const query = new URLSearchParams({
          page: (pagination.page + 1).toString(),
          limit: pagination.pageSize.toString(),
          ...(data?.debouncedValue?.length > 0 && {
            search: data?.debouncedValue,
          }),
        });

        const response = await API_CALL.ContactUs.get(query);
        setContactUs(response?.data?.data || { totalCount: 0, contactUs: [] });
      } catch (error) {
        console.error("Error fetching contact us:", error);
        toast.dismiss();
        toast.error("Failed to fetch contact");
      } finally {
        setIsLoading(false);
      }
    },
    [pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    fetchContactUs({ debouncedValue });
  }, [debouncedValue, fetchContactUs]);

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize, page: 0 }));
  };

  const handleDelete = async (id) => {
    try {
      setModalState((prev) => ({ ...prev, isDeleteModalOpen: false }));
      await API_CALL.ContactUs.delete(id);
      fetchContactUs({ debouncedValue });
      toast.dismiss();
      toast.success("Contact deleted successfully");
    } catch (error) {
      console.error("Error deleting contact:", error);
      toast.dismiss();
      toast.error("Failed to delete contact");
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => capitalizeFirstLetter(params.value) || "-",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => params.value || "-",
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      minWidth: 200,
      renderCell: (params) =>
        params?.value?.length > 20
          ? `${params?.value?.substring(0, 20)}...`
          : params?.value || "-",
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => showDate(params?.value) || "-",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          <IconButton
            onClick={() => {
              setModalState((prev) => ({
                ...prev,
                isFormModalOpen: true,
                data: params.row,
              }));
            }}
            size="small"
            sx={{
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
            }}
          >
            <IoIosEye
              style={{ fontSize: "1.3rem", color: theme.palette.primary.dark }}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              setModalState((prev) => ({
                ...prev,
                isDeleteModalOpen: true,
                data: {
                  _id: params?.row?._id,
                  title: params?.row?.name,
                },
              }));
            }}
            size="small"
            sx={{
              color: theme.palette.error.main,
              "&:hover": {
                backgroundColor: theme.palette.error.light,
                color: theme.palette.error.dark,
              },
            }}
          >
            <Delete sx={{ fontSize: "1.3rem" }} />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="h5"
              component="h1"
              sx={{ mb: { xs: 2, sm: 0 } }}
            >
              Contact Us
            </Typography>
          </Box>
          <Box sx={{ mb: 3 }}>
            <TextField
              placeholder="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setPagination((prev) => ({ ...prev, page: 0 }));
              }}
              color="primary"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: theme.palette.primary.dark,
                  },
                },
              }}
              InputProps={{ startAdornment: <SearchIcon /> }}
              fullWidth
            />
          </Box>
          <DataGrid
            rows={contactUs.contactUs}
            columns={columns}
            pagination
            page={pagination.page}
            pageSize={pagination.pageSize}
            rowCount={contactUs.totalCount}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            loading={isLoading}
            paginationMode="server"
            getRowId={(row) => row._id}
            disableSelectionOnClick
            checkboxSelection
            autoHeight
            color="primary.main"
            sx={{
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
              "& .MuiDataGrid-columnHeaders": {
                borderBottom: "2px solid rgba(224, 224, 224, 1)",
              },
              "& .MuiDataGrid-row": {
                "&:nth-of-type(odd)": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              },
            }}
          />
        </CardContent>
      </Card>
      <DeleteModal
        isModalOpen={modalState.isDeleteModalOpen}
        setIsModalOpen={(isOpen) =>
          setModalState((prev) => ({ ...prev, isDeleteModalOpen: isOpen }))
        }
        handleDelete={handleDelete}
        payload={modalState.data}
      />
      <DescriptionModal
        open={modalState.isFormModalOpen}
        setOpen={() =>
          setModalState((prev) => ({ ...prev, isFormModalOpen: false }))
        }
        payload={modalState.data}
      />
    </Box>
  );
};
