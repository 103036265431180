import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import { WarningAmber } from "@mui/icons-material";

const DeleteModal = ({
  isModalOpen,
  setIsModalOpen,
  handleDelete,
  payload,
}) => {
  const [isDisable, setIsDisable] = useState(false);
  const theme = useTheme();

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: { xs: "12px", sm: "16px" },
          padding: { xs: "12px", sm: "16px" },
          width: { xs: "90%", sm: "400px" },
          maxWidth: { xs: "320px", sm: "500px" },
          margin: "auto",
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: { xs: 0.5, sm: 1 },
          textAlign: "center",
          pt: { xs: 1, sm: 2 },
        }}
      >
        <WarningAmber sx={{ fontSize: { xs: 32, sm: 40 }, color: "#ff9800" }} />
        <Typography
          variant="h6"
          fontWeight="bold"
          fontSize={{ xs: "1rem", sm: "1.25rem" }}
        >
          Are You Sure?
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            textAlign: "center",
            fontSize: { xs: "0.875rem", sm: "1rem" },
            color: "#555",
            px: { xs: 0.5, sm: 1 },
          }}
        >
          Are you sure you want to permanently delete{" "}
          <Typography component="span" fontWeight="bold" color="error">
            {payload?.title}
          </Typography>
          ? This action cannot be undone.
        </DialogContentText>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: "center",
          padding: { xs: "8px", sm: "16px" },
          gap: { xs: 1, sm: 2 },
        }}
      >
        <Button
          onClick={() => setIsModalOpen(false)}
          variant="outlined"
          sx={{
            width: { xs: "100px", sm: "120px" },
            borderRadius: "8px",
            textTransform: "none",
            fontSize: { xs: "0.8rem", sm: "0.875rem" },
            "&:hover": {
              color: theme.palette.primary.dark,
              borderColor: theme.palette.primary.dark,
            },
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isDisable}
          onClick={() => {
            setIsDisable(true);
            handleDelete(payload?._id);
          }}
          variant="contained"
          color="primary"
          sx={{
            width: { xs: "100px", sm: "120px" },
            borderRadius: "8px",
            textTransform: "none",
            fontSize: { xs: "0.8rem", sm: "0.875rem" },
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
