import React from "react";
import {
  RichTextEditorComponent,
  Toolbar,
  Inject,
  HtmlEditor,
  Link,
  Image,
  QuickToolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { registerLicense } from "@syncfusion/ej2-base";

// Import styles
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-richtexteditor/styles/material.css";

// Register Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1NpRmNGfV5ycEVFal1ZTnRZUiweQnxTdEFjUHxecHdUT2VUUkZ/WQ=="
);

const RichTextEditor = ({ value, onChange }) => {
  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "Formats",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "ClearFormat",
      "Print",
      "SourceCode",
      "Undo",
      "Redo",
    ],
  };

  return (
    <RichTextEditorComponent
      value={value}
      change={(args) => onChange(args.value)}
      toolbarSettings={toolbarSettings}
      height="300px" // Adjust this value as needed
      maxHeight="1000px" // Adjust this value as needed
    >
      <Inject services={[Toolbar, HtmlEditor, Link, Image, QuickToolbar]} />
    </RichTextEditorComponent>
  );
};

export default RichTextEditor;
