import React, { useState, useEffect } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CommonLoader from "src/theme/loader";
import { API_CALL } from "src/services/APICalls";

const schema = yup.object().shape({
  oldPassword: yup.string().required("Old password is required"),
  newPassword: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
    )
    .test(
      "not-same-as-old",
      "New password must be different from the old password",
      function (value) {
        return this.parent.oldPassword !== value;
      }
    )
    .required("New password is required"),
  confirmNewPassword: yup
    .string()
    .oneOf(
      [yup.ref("newPassword"), null],
      "Passwords must be same as new password"
    )
    .required("Confirm new password is required"),
});

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 600,
  margin: "auto",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(4),
  boxShadow: theme.shadows[3],
}));

export const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
    confirm: false,
  });
  const [disable, setDisable] = useState({ confirm: true });

  const navigate = useNavigate();
  const theme = useTheme();

  const {
    control,
    reset,
    // setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const newPassword = useWatch({
    control,
    name: "newPassword",
    defaultValue: "",
  });

  useEffect(() => {
    const validateNewPassword = async () => {
      if (newPassword.length === 0) {
        setDisable((prev) => ({ ...prev, confirm: true }));
      } else {
        const result = await trigger("newPassword");
        setDisable((prev) => ({ ...prev, confirm: !result }));
      }
    };

    validateNewPassword();
  }, [newPassword, trigger]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await API_CALL.users.ResetPassword({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });
      setIsLoading(false);
      reset();
      toast.dismiss();
      toast.success("Password reset successfully");
      navigate(-1);
    } catch (error) {
      setIsLoading(false);
      console.error("Error resetting password:", error);
      toast.dismiss();
      toast.error(
        error?.response?.data?.message ||
          "Failed to reset password. Please try again."
      );
    }
  };

  return (
    <StyledCard>
      <CardContent sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CommonLoader show={isLoading} />
        </Box>
        <Typography sx={{ paddingBottom: "20px" }} variant="h4" gutterBottom>
          Reset Password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="oldPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Old Password"
                    type={showPassword.old ? "text" : "password"}
                    fullWidth
                    variant="outlined"
                    error={!!errors.oldPassword}
                    helperText={errors.oldPassword?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              setShowPassword((prev) => ({
                                ...prev,
                                old: !showPassword.old,
                              }));
                            }}
                            edge="end"
                          >
                            {showPassword.old ? (
                              <VisibilityOff
                                sx={{
                                  fontSize: "1.3rem",
                                  color: theme?.palette?.error?.main,
                                }}
                              />
                            ) : (
                              <Visibility
                                sx={{
                                  fontSize: "1.3rem",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="newPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="New Password"
                    type={showPassword.new ? "text" : "password"}
                    fullWidth
                    variant="outlined"
                    error={!!errors.newPassword}
                    helperText={errors.newPassword?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              setShowPassword((prev) => ({
                                ...prev,
                                new: !showPassword.new,
                              }));
                            }}
                            edge="end"
                          >
                            {showPassword.new ? (
                              <VisibilityOff
                                sx={{
                                  fontSize: "1.3rem",
                                  color: theme?.palette?.error?.main,
                                }}
                              />
                            ) : (
                              <Visibility
                                sx={{
                                  fontSize: "1.3rem",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="confirmNewPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={disable.confirm}
                    label="Confirm New Password"
                    type={showPassword.confirm ? "text" : "password"}
                    fullWidth
                    variant="outlined"
                    error={!!errors.confirmNewPassword}
                    helperText={errors.confirmNewPassword?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              setShowPassword((prev) => ({
                                ...prev,
                                confirm: !showPassword.confirm,
                              }));
                            }}
                            edge="end"
                          >
                            {showPassword.confirm ? (
                              <VisibilityOff
                                sx={{
                                  fontSize: "1.3rem",
                                  color: theme?.palette?.error?.main,
                                }}
                              />
                            ) : (
                              <Visibility
                                sx={{
                                  fontSize: "1.3rem",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end", padding: 2 }}>
        <Button
          color="primary"
          onClick={() => navigate(-1)}
          variant="outlined"
          sx={{
            marginRight: 1,
            "&:hover": {
              color: theme?.palette?.primary?.dark,
              borderColor: theme?.palette?.primary?.dark,
            },
          }}
          disabled={isLoading}
        >
          Back
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
          sx={{
            color: "#FFFFFF",
            "&:hover": { backgroundColor: theme.palette.primary.dark },
          }}
          disabled={isLoading}
        >
          Reset Password
        </Button>
      </CardActions>
    </StyledCard>
  );
};
